.bot-icon{
  position: fixed; 
  bottom: 0px; 
  right: 0px;
  padding: 20px;
  background-color: #243782;
  border-radius: 50px;
  margin: 0px 30px 30px 0px;
  color: #fff;
  cursor: pointer;
}
.pop-background{
  background-color: #fff;
  color: #243782;
  height: 500px;
  width: 400px;
  border: 2px solid #243782;
}
.navbar-background{
  background-color: #243782;
  color: #fff;
  font-family: "Labrada";
  padding: 10px 20px;
  font-weight: 600;
  font-size: 25px;
}

.redirection-link{
  text-decoration: none;
}
/* Login Page css */

.login-input{
  height: 30px;
  padding: 8px;
}
.login-input::placeholder{
  font-family: 'Encode Sans Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.44);
}
.login{
  margin-left:auto;
  margin-right:auto;
}
.admin{
  margin-left:auto;
  margin-right:auto;
}

.loginAndAdmin{
  display: flex;
  justify-content:center;
  margin: 60px auto;
  background:#fff;
  cursor:pointer;
  /* border: 1px solid #C0CEFF; */
  /* box-shadow: 0px 15px 15px rgba(36, 55, 130, 0.2); */
  /* border-radius: 40px 0px; */

}
.loginAndClear{
  margin-left:80px;
  /* justify-content:center; */
}
.border-line{
        border: 0.3px solid #243782;
        background: #C0CEFF;
        width: 99%;
        height: 0px;
        margin: 0px 0px;
        margin-bottom:0px;
        
}

.Rectangle{
  margin: auto;
  /* justify-content:center; */
  /* width: 10%; */
}
.logo{
  text-align:center;
  /* justify-content:center; */
  /* margin-top:40px; */
  margin: auto;
  background-color: #fff;
  border-radius: 50px;
  width: 35%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height:80%;
  /* margin-top:150px; */
  /* margin-left:auto;
  margin-right:auto; */
  padding:30px 0px 0px 0px;
  
  
}
.username{
  display:grid;
  font-family: 'Encode Sans Condensed';
  text-align:center;
  width:60%;
  row-gap:20px;
  margin-left:80px;
  margin-bottom:15px;
  /* margin-right:auto; */

}
.password{
  display:grid;
  text-align:center;
  width:60%;
  margin-left:80px;
  font-family: 'Encode Sans Condensed';
  /* margin-right:auto; */
  
}
.submit{
  display:grid;
  justify-content:center;
  cursor:pointer;
  margin-top:30px;
  margin-left:20px;
  /* background: rgba(243, 253, 252, 0.75); */
  /* background-color:#243782; */
  /* border: 1px solid rgba(0, 0, 0, 0.75); */
  /* width:fit-content; */
}
.clear{
  display:grid;
  justify-content:center;
  cursor:pointer;
  margin-top:30px;
  outline:hidden;
  /* background: rgba(243, 253, 252, 0.75);
  border: 1px solid rgba(0, 0, 0, 0.75); */
  width:fit-content;  
}
.clear input{
  outline:none;
  border:none;
  font-size:12px;
}
.clear :hover{
  text-decoration: underline;
  color:#243782
}

.stellantisLogo{
  width: 230px;
  /* margin-bottom:30px; */
  margin-bottom:5px;
}
.each-user-background{
  width: fit-content;
  color: rgba(36,55,130,0.3);

  /* padding: 18px 23px; */
  font-family: 'Encode Sans Condensed';
  font-style: normal;
  font-weight: 700;
  /* border-radius: 39px; */
  font-size: 24px;
  line-height: 24px;
  cursor: pointer;
  /* transition: all 1s; */
}
.each-user-background:hover , .each-user-background-active{
  color:#243782;

  /* padding: 18px 23px; */
  font-family: 'Encode Sans Condensed';
  /* border-radius: 39px; */
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  cursor: pointer;
  /* transition: all 1s; */
}
.login-button{
  cursor: pointer;
  margin-bottom: 20px;
  background: rgba(243, 253, 252, 0.75);
  border: 1px solid rgba(0, 0, 0, 0.75);
  padding: 2px 10px;
  font-family: 'Encode Sans Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

/* ChatBot Home Css */
.header-background{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 15px 20px 40px;
  display: flex;
}
.header-flex{
  flex: 0 0 80%;
}
.header-background img{
  height: 50px;
}
.header-section2{
  display: flex;
  padding-top: 10px;
  column-gap: 17px
}
.header-text{
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #243782;
}
.header-section2 img{
  width: 40px;
  height: 31px;
  cursor: pointer;
}
.logout-text{
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #243782;
  background-color: transparent;
  cursor: pointer;
}
.body-background{
  background-color: #F5F8F9;
  height: 100%;
  padding-bottom: 30px;
}
.input-text{
  display: flex;
  width: 1380px;
  /* height: 65px; */
}
.search-input{
  background: #FFFFFF;
  border: 3px solid #243782;
  box-shadow: 1px 1px 18px 3px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 100%;
  padding: 10px 0px 10px 20px;
  cursor: pointer;
  /* height: fit-content; */
}
.search-input::placeholder{
  font-family: 'Kiwi Maru';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.input{
  position: fixed; 
  bottom: 0px; 
  left: 0px;
  /* padding-top: 20px; */
  column-gap: 20px;
  background-color: #F5F8F9;
  width: 100%;
  padding-left: 20px;
  padding-bottom: 20px;
}
.bottom{
  display: flex;
}
.bottom-search{
  flex: 0 0 60%;
}
.bottom-section2{
  display: flex;
  flex: 0 0 20%;
}
.bottom-icons{
  flex: 0 0 20%;
}
.icon-align{
  margin-top: 10px;
  color: #243782;
  /* font-size: 40px; */
}
.icon-align:hover{
  cursor: pointer;
}
.chat-align{
  margin: 20px 8px;
  display: flex;
  column-gap: 12px;
}
.chat-align2{
  margin: 5px 8px;
  display: flex;
  column-gap: 12px;
}
.userchat-align{
  margin: 20px 8px;
  display: flex;
  column-gap: 12px;
  /* float: right; */
  justify-content: end;
}
.chat-textbackground{
  background: rgba(228, 242, 210, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  max-width: 30%;
  padding: 13px 20px;
  font-family: 'Encode Sans Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}
.userchat-textbackground{
  background: rgba(249, 231, 203, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  max-width: 30%;
  padding: 13px 20px;
  font-family: 'Encode Sans Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}
.yesornotext{
  font-family: 'Encode Sans Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 10px 0px 0px 65px;
}
.chart-align{
  width: 100%;
  /* margin-bottom: 48px; */
  /* display: flex; */
  padding-bottom: 20px;
  padding-left: 30px;
}
.bargraph{
  width: fit-content;
  height: fit-content;
}

/* voice icon style change */
.audio-recorder {
    color: #243782 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    height: 30px !important;
    /* width: 50px !important; */
    padding: 0px I !important;
}
.audio-recorder-mic{
  color: #243782 !important;
  height: 26px !important;
}
.position-align{
  margin-bottom: 70px;
}